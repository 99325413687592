import { createRouter, createWebHashHistory } from 'vue-router';

// Определяем маршруты
const routes = [
    {
        name: 'index',
        path: '/',
        component: () => import('../components/WorkspaceComponent.vue')
    },

    {
        name: 'FuzzyLong',
        path: '/fuzzylong',
        component: () => import('../widgets/Fuzzy/TradingComponent.vue')
    },
    {
        name: 'FuzzyLogic',
        path: '/fuzzylogic',
        component: () => import('../components/FuzzyLogic.vue')
    },
    {
        name: 'FractalVectorPlus',
        path: '/fractalvectorplus',
        component: () => import('../components/FractalVectorPlus.vue')
    },
    {
        name: 'DynamicAnalyzerPlus',
        path: '/dynamicanalyzerplus',
        component: () => import('../components/DynamicAnalyzerPlus.vue')
    },

    {
        name: 'EventsTimeline',
        path: '/events',
        component: () => import('../components/EventsTimeline.vue')
    },

    {
        name: 'AnomalyDetector',
        path: '/anomaly',
        component: () => import('../components/AnomalyDetector.vue')
    },

    {
        name: 'hybridForecastRemote',
        path: '/distribution',
        component: () => import('../components/HybridForecastRemote.vue')
    },
    {
        name: 'hybridForecastParent',
        path: '/hybridforecast2',
        component: () => import('../components/HybridForecastParent.vue')
    },
    {
        name: 'hybridForecast',
        path: '/hybridforecast',
        component: () => import('../components/HybridForecast.vue')
    },
    {
        name: 'strengthCoeff',
        path: '/strengthcoeff',
        component: () => import('../components/StrengthCoeff.vue')
    },
    {
        name: 'geoDistance',
        path: '/geodistance',
        component: () => import('../components/GeoDistance.vue')
    },
    {
        name: 'dynamicAnalyzer',
        path: '/dynamicanalyzer',
        component: () => import('../components/DynamicAnalyzer.vue')
    },
    {
        name: 'calculator',
        path: '/calculator',
        component: () => import('../components/CalculatorPosition.vue')
    },
    {
        name: 'volumeProfileChart',
        path: '/volumeprofilechart',
        component: () => import('../components/VolumeProfileChart.vue')
    },
    {
        name: 'fractalVector',
        path: '/fractalvector',
        component: () => import('../components/FractalVector.vue')
    },
    {
        name: 'candlesHistory',
        path: '/candleshistory',
        component: () => import('../components/CandlesHistory.vue')
    },
    {
        name: 'candlesCharts',
        path: '/candlescharts',
        component: () => import('../components/CandlesCharts.vue')
    },
    {
        name: 'candlesChart',
        path: '/candleschart',
        component: () => import('../components/CandlesChart.vue')
    },
    {
        name: 'candleStream',
        path: '/candlestream/:ticker',
        component: () => import('../components/CandlesChartStream.vue')
    },
    {
        name: 'candlesSeconds',
        path: '/candlesseconds/:ticker',
        component: () => import('../components/CandlesSeconds.vue')
    },

    {
        name: 'tradeStream',
        path: '/tradestream/:ticker',
        component: () => import('../components/TradesChartStream.vue')
    },
    {
        name: 'candlesFibonacci',
        path: '/candlesfibonacci/:ticker',
        component: () => import('../components/candlesFibonacci.vue')
    },
    {
        name: 'MarketWatch',
        path: '/marketwatch',
        component: () => import('../components/MarketWatch.vue')
    },


    {
        path: '/chat',
        component: () => import('../widgets/ChatTemplate.vue')
    },
    {
        path: '/beacon2',
        component: () => import('../widgets/BeaconLanguage2.vue')
    },
    {
        path: '/beacon',
        component: () => import('../widgets/BeaconLanguage.vue')
    },
    {
        path: '/markov',
        component: () => import('../widgets/MarkovStockSimulation.vue')
    },
    {
        name: "meshbot",
        path: '/meshbot',
        component: () => import('../widgets/Meshbot/MeshbotManager.vue')
    },
    {
        path: '/meshbottemplate',
        component: () => import('../widgets/Meshbot/MeshbotTemplate.vue')
    },

    {
        name: 'tradesMoney',
        path: '/tradesmoney',
        component: () => import('../components/TradesMoney.vue')
    },
    {
        name: 'tradesList',
        path: '/trades',
        component: () => import('../components/TradesList.vue')
    },
    {
        path: '/grid',
        component: () => import('../components/WidgetGrid.vue')
    },
    {
        path: '/intervals',
        component: () => import('../widgets/IntervalsTemplate.vue')
    },
    {
        name: 'workspace',
        path: '/workspace',
        component: () => import('../components/WorkspaceComponent.vue')
    },
    {
        path: '/alororderbooks',
        component: () => import('../components/AlorOrderbooks.vue')
    },

    {
        path: '/alorcandles',
        component: () => import('../components/AlorCandles.vue')
    },
    {
        path: '/alorquotes',
        component: () => import('../components/AlorQuotes.vue')
    },
    {
        path: '/alortrades',
        component: () => import('../components/AlorTrades.vue')
    },
    {
        path: '/alortradesplus',
        component: () => import('../components/AlorTradesPlus.vue')
    },
    {
        path: '/tcscandles',
        component: () => import('../components/TcsCandles.vue')
    },
    {
        path: '/tcstrades',
        component: () => import('../components/TcsTrades.vue')
    },
];

// Создаем роутер
const router = createRouter({
    history: createWebHashHistory(), // Используем hash history
    routes,
});

// Экспортируем роутер
export default router;
